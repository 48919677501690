import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import PackageAgencyResponse from '@acdc/shared/src/features/packageAgency/PackageAgencyResponse';
import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import arrayDiffById from '@acdc/shared/src/utils/arrayDiffById';
import { useMemo } from 'react';
import ServiceUnit from '@acdc/shared/src/features/service/ServiceUnit.enum';
import useAdditionalServices from '../service/useAdditionalServices';
import usePackageAgenciesOf from '../packageAgency/usePackageAgenciesOf';
import usePackages from './usePackages';
import { OverridedPackage, getOverridedPackage } from './useOverridedPackage';
import useApiEntrypoint from '../useApiEntrypoint';

function getAllIncludedAdditionalServices(
    pack: PackageResponse | string,
    agency: AgencyResponse,
    additionalServices: ServiceResponse[]
): ServiceResponse[] {
    return additionalServices.filter((service) => {
        const servicePackageOverride =
            agency.servicePackageOverrides?.collection?.find(
                (sp) =>
                    matchOnId(sp.service, service) &&
                    matchOnId(sp.package, pack)
            );

        if (servicePackageOverride?.unit === ServiceUnit.DISABLED) {
            // si le service est désactivé dans ce pack il n'est pas inclus
            return false;
        }

        if (!servicePackageOverride || servicePackageOverride?.price === null) {
            // si il n'y a pas de prix configuré c'est que le service est inclus
            return true;
        }

        return false;
    });
}

export interface PackageSummaryData {
    overridedPackage: OverridedPackage;
    packageAgency: PackageAgencyResponse;
    previousOverridedPackage: OverridedPackage | null;
    /**
     * Toutes les prestations annexes qui sont incluses dans ce pack.
     */
    allIncludedAdditionalServices: ServiceResponse[];
    /**
     * Les prestations annexes qui sont incluses dans ce pack mais pas dans le précédent.
     */
    newIncludedAdditionalServices: ServiceResponse[];
}

/**
 * Retourne la liste des packs actifs d'une agence avec pour chaque pack la liste de ses prestations annexes incluses
 * en plus par rapport au pack actif précédent.
 */
function usePackagesSummaries(
    agency: AgencyResponse | undefined
): PackageSummaryData[] {
    const packages = usePackages();
    const packageAgencies = usePackageAgenciesOf(agency, true);
    const additionalServices = useAdditionalServices(agency);
    const apiEntrypoint = useApiEntrypoint();

    return useMemo(() => {
        if (!agency || !packageAgencies) {
            return [];
        }

        return packageAgencies?.reduce(
            (curr: PackageSummaryData[], packageAgency) => {
                const pack = packages?.find((p) =>
                    matchOnId(p, packageAgency.package)
                );
                if (!pack) {
                    return curr; // pas sensé arriver
                }
                const previousSummary =
                    curr.length > 0 ? curr[curr.length - 1] : undefined;

                const allIncludedAdditionalServices =
                    getAllIncludedAdditionalServices(
                        pack!,
                        agency,
                        additionalServices || []
                    );

                const newIncludedAdditionalServices = previousSummary
                    ? arrayDiffById(
                          allIncludedAdditionalServices,
                          previousSummary.allIncludedAdditionalServices
                      )
                    : allIncludedAdditionalServices;

                curr.push({
                    overridedPackage: getOverridedPackage(
                        pack,
                        agency,
                        packageAgency,
                        apiEntrypoint
                    ),
                    packageAgency,
                    previousOverridedPackage:
                        previousSummary?.overridedPackage || null,
                    newIncludedAdditionalServices,
                    allIncludedAdditionalServices,
                });

                return curr;
            },
            []
        );
    }, [agency, packageAgencies, packages, additionalServices, apiEntrypoint]);
}

export default usePackagesSummaries;
